table.tour_search {
    border: 0;
    padding: 0;
    border-spacing: 6;
    align: center;
    width: 50%;
    margin: 0 auto;
}

.tour_search_container {
    display: flex;
    justify-content: center;
    width: 100%;
}
