td.rareness {
    text-align: center;
}

/*
 * because of the menu bar, when we scroll to a pick ID, that
 * pick is covered up. Make sure we scroll to 75px above that
 */
a.itemlink {
    scroll-margin-top: 75px;
}

table.items td.tin {
    border: 1px solid gray;
    vertical-align: top;
}

span.id {
    font-size: small;
    color: gray;
}
