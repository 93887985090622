table.items {
    margin: auto;
    width: 100%;
    /* border-collapse collapse; */
}

table.items tr:hover td {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

table.items th {
    padding: 12px;
}

table.items thead span.sortheader {
    text-decoration: none;
    color: white;
}

table.items thead span.ascending::after {
    content: '\25B4';
    display: inline-block;
    margin-left: 1em;
}

table.items thead span.descending::after {
    content: '\25BE';
    display: inline-block;
    margin-left: 1em;
}

table.items thead span.neither::after {
    content: '\25B4\25BE';
    display: inline-block;
    margin-left: 1em;
}
