/*
 * global stuff that is react-only for now
 * 
 * really-global stuff goes in /css/ipom.css
 *
 * but react-only global stuff goes here
 */

h1 {
    margin-top: 0px;
}
