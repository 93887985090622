/* Main container: Two-column layout on desktop */
.profile-container {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: nowrap;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .profile-container {
        flex-direction: column;
    }

    .tour-map {
        order: 1;
        width: 100%;
    }

    .location-lists {
        order: 2;
        width: 100%;
    }
}
