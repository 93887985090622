/*
 * if you want a table to be h-scrollable, add a div
 * of this class around it.
 */
div.table-hscroll-container {
    overflow-x: auto;
    width: 100%;
    overflow-y: hidden;
    height: min-content;
}
