table.stats td {
    text-align: right;
}

table.stats th {
    text-align: left; padding: 2px 10px 2px 10px;
}

#item-stats {
    display: inline;
}

span.missing,
.stats .missing {
    color: red;
}

span.incomplete,
.stats .incomplete {
    color: yellow;
}

span.complete,
.stats .complete {
    color: blue;
}

span.extra,
.stats .extra {
    color: green;
}

/* phones */
@media screen and (max-width: 800px) {
    /*
     * For inline scrolling to work, the div must block or inline-block,
     * however, for stuff like StatsTable, on desktop, we want it inline
     * and don't need it to be hscroll-able. But on mobile we do, and it's
     * wide enough to not need to be inline. So for mobile ONLY, we force
     * this to be display: block no matter what.
     */
    div#item-stats {
        display: block;
    }
}
