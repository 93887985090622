/* On desktop, keep width controlled */
.quick_menu .react-select__control {
  width: 10%;
  max-width: 120px;
}

/* For mobile, let it be wider for readability */
@media (max-width: 700px) {
  .quick_menu .react-select__control {
    width: 100%;
    max-width: 200px;
  }
}
