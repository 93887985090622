/*
 * Sets BACKGROUND colors for the item images and amounts
 *
 * For text color see PickStats.css
 */
tr.missing td.colored,
tr.missing td.colored a {
    background-color: red;
    color: black;
    border: 1px solid gray;
}

tr.incomplete td.colored,
tr.incomplete td.colored a {
    background-color: yellow;
    color: black;
}

tr.complete td.colored,
tr.complete td.colored a {
    background-color: blue;
    color: white;
}

tr.extra td.colored,
tr.extra td.colored a {
    background-color: green;
    color: black;
}

/*
 * start with color1 one solid. from 30% down
 * to 70% down blend from color1 -> color2,
 * then color the rest of the way
 */
tr.missing_to_incomplete td.colored {
    background: linear-gradient(
        to bottom,
        red,
        red 30%,
        yellow 70%,
        yellow
    );
    color: black;
}

tr.missing_to_incomplete td.colored a {
    color: black;
}

tr.missing_to_complete td.colored {
    background: linear-gradient(
        to bottom,
        red,
        red 30%,
        blue 70%,
        blue
    );
    color: white;
}

tr.missing_to_complete td.colored .have_label {
    color: black;
}

tr.missing_to_complete td.colored a {
    color: white;
}

tr.missing_to_extra td.colored {
    background: linear-gradient(
        to bottom,
        red,
        red 30%,
        green 70%,
        green
    );
    color: black;
}

tr.missing_to_extra td.colored a {
    color: black;
}

tr.incomplete_to_complete td.colored {
    background: linear-gradient(
        to bottom,
        yellow,
        yellow 30%,
        blue 70%,
        blue
    );
}

tr.incomplete_to_complete td.colored .have_label {
    color: black;
}

tr.incomplete_to_complete td.colored a,
tr.incomplete_to_complete td.colored .incoming_label {
    color: white;
}

tr.incomplete_to_extra td.colored {
    background: linear-gradient(
        to bottom,
        yellow,
        yellow 30%,
        green 70%,
        green
    );
    color: black;
}

tr.incomplete_to_extra td.colored a {
    color: black;
}

tr.complete_to_extra td.colored {
    background: linear-gradient(
        to bottom,
        blue,
        blue 30%,
        green 70%,
        green
    );
    color: white;
}

tr.complete_to_extra td.colored .incoming_label,
tr.complete_to_extra td.colored a {
    color: black;
}

/*
 * not exactly a "color", but needed in roughly the same places and a similar
 * kind of thing
 */
td.tin {
    border: 1px solid gray;
    vertical-align: top;
}

.incoming_label,
.have_label {
    font-size: x-small;
}
