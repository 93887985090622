#status {
    transition: opacity 0.4s linear;
    opacity: 0;
    overflow-y: auto;
    max-height: 95%;
}

.lowstatus {
    position: fixed;
    bottom: 15px;
    left: 50%;
    margin-left: -500px;
    width: 1000px;
    height: 30px;
    background-color: green;
    border: 1px solid white;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 6px;
    text-align: center;
}

.bigstatus {
    position: fixed;
    color: white;
    background-color: darkslategray;
    top: 15px;
    left: 50%;
    margin-left: -38%;
    width: 76%;
    border: 1px solid green;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 6px;
}

.statusbackdrop {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
    background-color: gray;
}
