.tour_menu_dropdown {
    width: 100px;
}

.setlistbox {
    position: fixed;
    color: white;
    background-color: black;
    top: 15px;
    left: 50%;
    margin-left: -38%;
    width: 76%;
    border: 1px solid white;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 6px;
}
