.badge {
  display: inline-block;
  margin-left: 4px;
  background-color: gray;
  color: white;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  font-size: 10px;
  text-align: center;
  line-height: 14px;
  font-weight: bold;
  position: relative;
  top: -6px;
  cursor: pointer;
}

.badge:hover::after {
  content: attr(title);
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  visibility: visible;
}

.badge::after {
  content: '';
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
