.main div.rightbar {
    background-color: black;
    margin: 0px 5px 5px 5px;
    float: right;
    padding: 0px 0px 15px 15px;
    font-size: small;
    width: 20%;
    border: 2px solid #bbbbbb;
    height: 100%;
}
