input.livesearch {
    background-image: url("https://www.ipom.com/images/searchicon.png");
    background-position: 5px 5px;
    background-repeat: no-repeat;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 6px;
    transition: width 0.4s ease-in-out;
    width: 40%;
}

#wantlistpreview, #kbshortcuts {
    float: right;
    margin: 10px;
}
