div.loading_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

img.loading {
    margin: 40px;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
