table.tour_dates {
  float: center;
  border: 0;
  width: 100%;
}

table.tour_dates td {
  padding-left: 4px;
  padding-right: 4px;
}

table.tour_dates caption {
  font-size: large;
  text-align: left;
}

table.tour_dates th {
  text-transform: uppercase;
  padding: 4px;
}

.tour_notseen {
  color: gray;
}

tr.future {
    background-color: slategray;
}

tr.canceled {
    text-decoration: line-through;
}
