/*
 * Both Chrome on desktop as well as many phones make
 * the input field for qty/incoming massive.
 *
 * On small screens, to conserve space, we disable the
 * up/down arrows and limit it to 3 chars (a 3-digit
 * number will fit).
 */
@media screen and (max-width: 1024px) {
    input.qty, input.incoming {
        width: 3ch;
        /* next two lines disable it in chrome/safari */
        -webkit-appearance: none;
        margin: 0;
        /* disables in FF */
        -moz-appearance: textfield;
    }
}

/*
 * On large screens 6 chars is enough for a 3-digit numbers
 * and the up/down arrows on every browser I tested.
 */
@media screen and (min-width: 1025px) {
    input.qty, input.incoming {
         width: 6ch;
    }
}
