/* For phones... */
@media screen and (max-width: 800px) {
    /*
     * These should never show up on mobile or take up space
     */
    .castle, .logo {
        width: 0px;
        height: 0px;
        display: none;
        visibility: hidden;
    }

    /* By default we hide the whole header */
    div.header {
        display: none;
        position: relative;
        z-index: 1000;
    }

	div.header.open {
        display: block;
    }

    /*
     * On mobile we basically just add one additional "level" to the
     * expanding menu. Instead of the main menu showing, a "menu button"
     * (with .mobile-menu-container class) is shown, when that is clicked,
     * the normal menu drops down.
     *
     * Unfortunately we have to do it outside the hierarchy of ULs to not
     * mess up the desktop version, which means toggling the display actually
     * happens in JS instead of CSS, but it's just that one piece.
     */
    .mobile-menu-container {
        display: block;
        background: linear-gradient(to bottom, #fefefe, #868686, #020202);
        height: 30px;
        padding: 0px 0px 0px 0px;
        clear: both;
        margin: 0;
        text-transform: uppercase;
    }

    nav .menu > li {
        /*
         * Since we're vertical instead of horizontal,
         * each row needs to have the background stripe
         */
        background: linear-gradient(to bottom, #fefefe, #868686, #020202);
        border-bottom: 1px solid white;
        /* clear side borders from desktop */
        border-right: none;
        border-left: none;
        width: 100%;
        display: block;
    }

    nav .dropdown-content {
        width: 100%;
        background-color: #bbbbbb;
        z-index: 1001;
        position: absolute;
    }

    /*
     * Unlike desktop where we use hover, here we have to use
     * clicked (active) since there's no hover on touch screens.
     *
     * The unfortunate part here is there's no 'unactive' until the
     * user taps somewhere else, but it's not too bad.
     */
    nav .dropdown:active .dropdown-content,
    nav .dropdown:active li
    {
        display: block;
        visibility: visible;
    }
}

/* Specifically for _not_ phones */
@media screen and (min-width: 801px) {
    div.header {
        position: sticky;
        top: -120px;
        /* This keeps images from scrolling over us */
        z-index: 1;

    }
    /* make sure the mobile menu button doesn't appear on desktop */
    .mobile-menu-container {
        display: none;
    }

    /* Horizontal menu - desktop only */
    nav .menu > li {
        display: inline-block;
        border-right: 2px solid #000000;
    }

    /* Need a starting border */
    nav .menu > li:first-child {
        border-left: 2px solid #000000;
    }

    /* Menu items look "depressed" when moused over for horizontal menu */
    nav .menu li > a:hover {
        background-color: #bbbbbb;
    }
    
    nav .menu li > span:hover {
        background-color: #bbbbbb;
    }

    nav .dropdown-content {
        background-color: #f9f9f9;
        padding: 0px;
    }
}

/*
 * mobile-menu-container is hidden unless
 * the screen is small, but we wnat it to have
 * the same fonts when it is visible.
 */
nav,
.mobile-menu-container {
    color: black;
    font-weight: bold;
    font-size: 12pt;
    width: 100%;
    text-align: center;
}

/*
 * the links are black (on silver background) with no decoration
 */
nav a:link, nav a:visited {
    text-decoration: none;
    color: black;
}

/* both menu and submenu: drop bullets, etc. */
nav ul {
    list-style-type: none;
}

/* BEGIN: Top level menu only */

nav .menu {
    background: linear-gradient(to bottom, #fefefe, #868686, #020202);
    height: 30px;
    padding: 0px 0px 0px 0px;
    clear: both;
    margin: 0;
}

/* Format menu items (both horizontal and vertical menus) */
nav .menu > li,
.mobile-menu-container {
    text-transform: uppercase;
    padding: 0;
}

/* Links in first level menu */
nav .menu > li > a,
nav .menu > li > span,
.mobile-menu-container > span {
    padding: 0 10px 0 10px;
    display: block;
    line-height: 30px;
}

/* BEGIN: Submenu stuff */

nav .submenu a {
    padding: 5px 10px 5px 10px;
    display: block;
}

nav .dropdown {
    position: relative;
    /*
     * NOTE: used to be inline-block for desktop
     * and block for mobile, but it looks like block
     * works for both
     */
    display: block;
}

nav .dropdown-content {
    /* display:none for most browsers, but mobile chrome
     * wants visibility:hidden */
    display: none;
    opacity: 0;
    position: absolute;
    z-index: 1;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 0px;
    transition: opacity 0.2s ease-in-out;
}

.dropdown-content.open {
    display: block;
    opacity: 1;
}

/*
nav .dropdown:hover .dropdown-content {
    display: block;
    opacity: 1;
}
*/
